import React, { useState } from 'react';
import {
  View,
  Text,
  Button,
  TextInput,
  Image,
  Modal,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
  Platform,
  Animated,
} from 'react-native';
import axios from 'axios';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { AuthContext, useAuthContext } from '../utils/ContextUtils';
import Constants from 'expo-constants';
import * as SecureStoreWeb from '../utils/SecureStoreWeb';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default class Login extends React.Component {
  constructor(props) {
    console.log('==================== Login.constructor start.');
    super(props);
    this.state = { email: '', password: '', loading: false, failed: false, modalVisible: false };
    this.modalTranslateY = new Animated.Value(-1000); // モーダルを画面外に配置
    console.log('==================== Login.constructor end.');
  }

  async componentDidMount() {
    console.log('==================== Login.componentDidMount start.');
    if (
      this.props.navigation.state &&
      this.props.navigation.state.params &&
      this.props.navigation.state.params.logout
    ) {
      this.deleteUser();
      await SecureStoreWeb.deleteItemAsyncWeb('user-info');
    } else {
      let strUserInfo = await SecureStoreWeb.getItemAsyncWeb('user-info');
      if (strUserInfo) {
        let userInfo = JSON.parse(strUserInfo);
        if (userInfo.token) {
          //let accessToken = await SecureStore.getItemAsync('access-token');
          //let client = await SecureStore.getItemAsync('client');
          //let uid = await SecureStore.getItemAsync('uid');
          //this.context.header = {
          //  accessToken: accessToken,
          //  client: client,
          //  uid: uid
          //};

          //let strUserInfo = await SecureStore.getItemAsync('user-info');
          //let userInfo = JSON.parse(strUserInfo);
          //this.context.user = userInfo;
          //console.log("==================== Login -> Main:" + this.context.user.id);
          //console.log("==================== Login -> Main:" + this.context.user.role);
          //this.props.navigation.navigate('Main');
          this.getUser(userInfo);
        }
      }
    }
    console.log('==================== Login.componentDidMount end.');
  }

  deleteUser() {
    console.log('==================== Login.deleteUser start.');
    axios
      .delete(Constants.manifest.extra.apiUrl + '/api/auth/sign_out', {
        headers: { Authorization: 'Bearer ' + this.context.user.token },
      })
      .then((response) => {
        //console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
    console.log('==================== Login.deleteUser end.');
  }

  getUser(userInfo) {
    console.log('==================== Login.getUser start.');
    axios
      .get(Constants.manifest.extra.apiUrl + '/api/auth/' + userInfo.id, {
        headers: { Authorization: 'Bearer ' + userInfo.token },
      })
      .then((response) => {
        console.log(response);
        this.context.user = userInfo;
        console.log(
          '==================== Login -> Main:' + this.context.user.id
        );
        console.log(
          '==================== Login -> Main:' + this.context.user.role
        );
        this.props.navigation.navigate('Main');
      })
      .catch((error) => {
        //console.error(error);
        console.log('ERROR!!');
        console.log(error);
      });
    console.log('==================== Login.getUser end.');
  }

  patchUser(pushToken) {
    console.log('==================== Login.patchUser start.');

    // web取得できない
    if (pushToken == undefined) {
      return;
    }

    let model = {
      registration: {
        push_token: pushToken,
      },
    };
    console.log(model);
    axios
      .patch(
        Constants.manifest.extra.apiUrl + '/api/auth/' + this.context.user.id,
        model,
        {
          headers: { Authorization: 'Bearer ' + this.context.user.token },
        }
      )
      .then((response) => {
        //console.log(response);
      })
      .catch((error) => {
        //console.error(error);
        console.log('ERROR!!');
        console.log(error);
      });
    console.log('==================== Login.patchUser end.');
  }

  renderLoginButton() {
    if (this.state.loading) {
      return <ActivityIndicator size="small" />;
    } else {
      {
        /*<Button title="ログイン" onPress={() => {this.onSubmit()}} />*/
      }
      return (
        <TouchableOpacity
          style={styles.containerLogin}
          onPress={() => this.onSubmit()}
        >
          <Text style={styles.textLogin}>ログイン</Text>
        </TouchableOpacity>
      );
    }
  }

  hojyokinButton() {
    return (
      
        <TouchableOpacity 
          style={styles.containerLogin} 
          onPress={() => this.infoModal()}
        >
        <FontAwesome5 name="info-circle" size={30} style={styles.info} />
        </TouchableOpacity>
      
    );
  }

  onModalPress() {
    console.log("onModalPress")
    this.state.modalVisible = true
    this.setState({ modalVisible: true });
  }

  // モーダルを閉じるメソッド
  closeModal() {
    Animated.timing(this.modalTranslateY, {
      toValue: -1000, // モーダルを画面外に戻す
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      this.setState({ modalVisible: false }); // アニメーションが終了したらモーダルを隠す
    });
  }

  infoModal() {
    console.log("infoModal")
    this.onModalPress();
    this.showModal();
  }
  
  // モーダルを表示するメソッド
  showModal() {
    this.setState({ modalVisible: true }, () => {
      Animated.timing(this.modalTranslateY, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }).start();
    });
  }

  onSubmit() {
    this.setState({ loading: true });
    //console.log("onSubmit!!");
    let model = { email: this.state.email, password: this.state.password };
    //model.email = this.email
    //model.password = this.password
    axios
      .post(Constants.manifest.extra.apiUrl + '/api/auth/sign_in', model)
      .then((response) => {
        //console.log(response);
        this.setState({ loading: false });
        //let accessToken = response.headers['access-token'];
        //let client = response.headers['client'];
        //let uid = response.headers['uid'];
        //SecureStore.setItemAsync('access-token', accessToken);
        //SecureStore.setItemAsync('client', client);
        //SecureStore.setItemAsync('uid', uid);
        //this.context.header = {
        //  accessToken: accessToken,
        //  client: client,
        //  uid: uid
        //};

        let user = response.data;
        SecureStoreWeb.setItemAsyncWeb('user-info', user);
        this.context.user = user;
        console.log('USER ID:' + this.context.user.id);
        console.log('ROLE   :' + this.context.user.role);

        // Push通知用トークン取得
        this.registerForPushNotificationsAsync().then((pushToken) =>
          this.patchUser(pushToken)
        );

        this.props.navigation.navigate('Main');
      })
      .catch((error) => {
        //console.error(error);
        console.log('ERROR!!');
        console.log(error);
        this.setState({ loading: false });
        this.setState({ failed: true });
      });
  }

  async registerForPushNotificationsAsync() {
    let token;
    // web は対応していない？
    if (Platform.OS === 'web') {
      return;
    }
    if (Device.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Push通知用のトークン取得に失敗しました。');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log(token);
    } else {
      // シミュレータ
      alert('Push通知は実機のみ対応です。');
      return;
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
    return token;
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          {this.hojyokinButton()}
          <Image
            style={styles.imageLogo}
            source={require('../assets/icon.png')}
          />
          <Text style={styles.textTitle}>{Constants.manifest.name}</Text>
        </View>
        <View style={styles.footer}>
          {this.state.failed && (
            <Text style={styles.textError}>ログインに失敗しました</Text>
          )}

          <TextInput
            style={styles.textInput}
            placeholder="メールアドレス"
            onChangeText={(email) => this.setState({ email })}
          />
          <TextInput
            style={styles.textInput}
            placeholder="パスワード"
            onChangeText={(password) => this.setState({ password })}
            secureTextEntry={true}
          />

          {this.renderLoginButton()}
          {/*<Button title="ログイン" onPress={() => {this.props.navigation.navigate('Main')}} />*/}
          
        </View>
        <Modal
          visible={this.state.modalVisible}
          transparent={true}
          animationType="none" // デフォルトのアニメーションを無効にする
        >
          <View style={styles.modalContainer}>
            <Animated.View style={[styles.modalContent, { transform: [{ translateY: this.modalTranslateY }] }]}>
              <View style={styles.closeContainer}>
                <TouchableOpacity
                  style={styles.closeButtonContainer}
                  onPress={() => this.closeModal()}
                >
                  <Text style={styles.closeButtonText}>×</Text>
                </TouchableOpacity>
              </View>
              <View>
                <View>
                  <Text style={styles.modaText}>会社概要</Text>
                  <Text></Text>
                  <Text style={styles.modaText}>運用元（運用会社）：G. T.ラボ株式会社</Text>
                  <Text style={styles.modaText}>ホームページ：https://gtlabo.jp</Text>
                  <Text style={styles.modaText}>お問い合わせ先：vegetchi.help@gtlabo.jp</Text>
                  <Text></Text>
                  <Text style={styles.modaText}>＜事業再構築補助金により作成＞</Text>
                </View>
              </View>
            </Animated.View>
          </View>
        </Modal>
      </View>
    );
  }
}
Login.contextType = AuthContext;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#009387',
  },
  containerM: {
    flex: 1,
  },
  modalMain: {
    margin: 20,
    textAlign: 'center', // テキストを中央揃え
  },
  header: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    //paddingHorizontal: 20,
    //paddingBottom: 100
    marginBottom: 30,
  },
  footer: {
    flex: 2,
    backgroundColor: '#fff',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 20,
    paddingVertical: 30,
    //marginTop: 100
  },
  info: {
    color: '#ffffff',
  },
  imageLogo: {
    backgroundColor: '#fff',
    width: 100,
    height: 100,
    paddingTop: 100,
    borderRadius: 5,
  },
  textTitle: {
    marginTop: 10,
    fontSize: 36,
    color: '#ffffff',
  },
  textInput: {
    height: 50,
    padding: 5,
    marginBottom: 10,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: 'gray',
  },
  containerLogin: {
    backgroundColor: '#009387',
    height: 50,
    justifyContent: 'center',
    borderRadius: 10,
    marginTop: 10,
  },
  textLogin: {
    textAlign: 'center',
    color: '#ffffff',
    fontSize: 20,
  },
  textError: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: 'center',
    color: 'red',
  },
  modalContainer: {
    flex: 1,
    // justifyContent: 'center', // 垂直方向で中央揃え
    marginTop: 50,
    alignItems: 'center', // 水平方向で中央揃え
    // backgroundColor: 'rgba(0, 0, 0, 0.5)', // モーダルの背景を半透明に
  },
  modalContent: {
    backgroundColor: 'white',
    borderWidth: 2,
    borderColor: 'white',
    borderRadius: 10,
    padding: 20,
    width: '80%', // 幅を80%に設定
    height: '28%', // 高さの最大値を80%に設定
    justifyContent: 'flex-start', // 内容を上に寄せる
    alignItems: 'stretch', // 子要素を全幅に
  },
  closeContainer: {
    alignItems: 'flex-end', // 右端に配置
    marginBottom: 10,
  },
  closeButtonContainer: {
    backgroundColor: '#009387',
    height: 40,
    width: 40,
    justifyContent: 'center',
    borderRadius: 10,
  },
  closeButtonText: {
    textAlign: 'center',
    color: '#ffffff',
    fontSize: 30,
  },
  modalMain: {
    // モーダルの内容に必要なスタイルを追加
  },
  modaText: {
    margin: 3,
    textAlign: 'center', // テキストを中央揃え
  },
});
