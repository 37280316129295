import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Modal,
  Text,
  Keyboard,
  Picker,
  TextInput,
  Dimensions,
  Image,
} from 'react-native';
import PropTypes from 'prop-types';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import axios from 'axios';
import Constants from 'expo-constants';
import * as ImagePicker from 'expo-image-picker';

const { width } = Dimensions.get('window');

export default class StatusUtils extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      apiResponse: null,
      selectedCrop: '',
      selectedGrowthStage: '',
      selectedGrowthStageName: '',
      apiCalled: false,
      message: '',
      image: null,
    };
  }

  closeModal() {
    Keyboard.dismiss();
    // this.setState({ modalVisible: false });
    this.setState({
      modalVisible: false,
      selectedCrop: '',             // 作物選択をリセット
      selectedGrowthStage: '',      // 育成状態選択をリセット
      selectedGrowthStageName: '',  // 育成状態名をリセット
      message: '',                  // メッセージをリセット
      image: null,                  // 画像をリセット
      apiCalled: false,             // API呼び出しフラグをリセット
    });
  }

  onModalPress() {
    this.setState({ modalVisible: true });

    if (!this.state.apiCalled) {
      this.ikuseiGet();
      this.setState({ apiCalled: true });
    }
  }

  ikuseiGet() {
    const { chatId, userId, tokenData } = this.props;
    axios
      .get(Constants.manifest.extra.apiUrl + '/api/user_ikusei', {
        params: { chat_id: chatId, user_id: userId },
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + tokenData,
        },
      })
      .then((response) => {
        this.setState({
          apiResponse: response.data,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.error('レスポンスエラー:', error.response);
        } else if (error.request) {
          console.error('リクエストエラー:', error.request);
        } else {
          console.error('エラー詳細:', error.message);
        }
      }
    );
  }

  async pickImage() {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
      alert('画像のアクセス許可が必要です');
      return;
    }

    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      this.setState({ image: result.assets[0].uri });
    }
  }

  // 画像プレビューを取り消す関数
  removeImage() {
    this.setState({ image: null });
  }


  handleSubmit() {
    const { chatId, tokenData, onSend } = this.props;
    const { selectedGrowthStage, selectedCrop, message, image } = this.state;
    const userId = this.state.apiResponse[0]?.user_id;
    const currentUserId = this.props.userId

    const requestData = [{
      ikusei_flag: 1,
      chat_id: chatId,
      user_id: userId,
      current_user_id: currentUserId,
      crops: [
        {
          crop_id: selectedCrop,
          stage: selectedGrowthStage,
        },
      ],
      text: message,
      image: image,
    }];

    console.log('リクエストデータ：', requestData);
    onSend(requestData);
    // axios
    //   .post(Constants.manifest.extra.apiUrl + '/api/user_ikusei_update', requestData, {
    //     headers: {
    //       Accept: 'application/json',
    //       Authorization: 'Bearer ' + tokenData,
    //     },
    //   })
    //   .then((response) => {
    //     // 成功した場合の処理
    //   })
    //   .catch((error) => {
    //     console.error('API送信エラー:', error);
    //   });
      this.closeModal();
  }

  handleCropChange(itemValue) {
    this.setState({
      selectedCrop: itemValue,
      selectedGrowthStage: '',
      selectedGrowthStageName: '',
    });
  }

  handleGrowthStageChange(itemValue, stageName) {
    this.setState({
      selectedGrowthStage: itemValue,
      selectedGrowthStageName: stageName,
    });
  }

  render() {
    const { selectedCrop, selectedGrowthStage, selectedGrowthStageName, apiResponse, message, image } = this.state;
    const crops = Array.isArray(apiResponse) && apiResponse[0]?.crop_data ? apiResponse[0].crop_data : [];
    const selectedCropData = Array.isArray(crops)
      ? crops.find((crop) => String(crop.crop_id) === String(selectedCrop)) || null
      : null;

    console.log("通った")

    let currentStageName = '';
    if (selectedCropData) {
      const currentStage = selectedCropData.crop_growth_stage.find(
        (stage) => String(stage.step) === String(selectedCropData.crop_status)
      );
      if (currentStage) {
        currentStageName = currentStage.name;
      }
    }

    const isTablet = width >= 768;

    return (
      <TouchableOpacity style={styles.container} onPress={() => this.onModalPress()}>
        <FontAwesome5 style={styles.buttonIcon_ikusei} name="seedling" size={22} />
        <Modal
          visible={this.state.modalVisible}
          animationType={'fade'}
          transparent={true}
          onRequestClose={() => this.closeModal()}
        >
          <View style={styles.modalBackground}>
            <View style={[styles.modalContainer, isTablet && styles.modalContainerTablet]}>
              <View style={styles.closeContainer}>
                <TouchableOpacity style={styles.closeButtonContainer} onPress={() => this.closeModal()}>
                  <Text style={styles.closeButtonText}>×</Text>
                </TouchableOpacity>
              </View>

              <FontAwesome5 style={styles.buttonIcon_ikusei} name="seedling" size={22} />
              <Text style={styles.modalTitle}>作物の育成通知</Text>

              <View style={styles.modalMain}>
                {apiResponse && apiResponse[0]?.crop_data ? (
                  <View style={styles.apiResponseContainer}>
                    <Text style={styles.label}>
                      作物を選択:　{selectedCrop && <FontAwesome5 name="check" size={16} color="green" />}</Text>
                    
                    <Picker
                      selectedValue={selectedCrop}
                      style={styles.picker}
                      onValueChange={(itemValue) => this.handleCropChange(itemValue)}
                    >
                      <Picker.Item label="作物を選んでください" value="" />
                      {crops.map((crop) => (
                        <Picker.Item key={crop.crop_id} label={crop.crop_name} value={crop.crop_id} />
                      ))}
                    </Picker>

                    

                    {selectedCrop && (
                      <>
                        <View style={styles.growthStageRow}>
                          <Text style={styles.label}>
                            育成状態を選択:　{selectedGrowthStage && <FontAwesome5 name="check" size={16} color="green" />}</Text>
                          {currentStageName && (
                            <Text style={styles.currentStageText}>現在の育成状態: {currentStageName}</Text>
                          )}
                        </View>
                        <Picker
                          selectedValue={selectedGrowthStage}
                          style={styles.picker}
                          onValueChange={(itemValue) => {
                            const selectedStage = selectedCropData?.crop_growth_stage.find(
                              (stage) => stage.id === itemValue
                            );
                            this.handleGrowthStageChange(itemValue, selectedStage?.name);
                          }}
                        >
                          <Picker.Item label="育成状態を選んでください" value="" />
                          {selectedCropData?.crop_growth_stage.map((stage) => (
                            <Picker.Item key={stage.id} label={stage.name} value={stage.step} />
                          ))}
                        </Picker>
                      </>
                    )}
                  </View>
                ) : (
                  <Text style={styles.loadingText}>
                    ユーザーの作物がまだ設定されていません。種まきが完了してから再度ご確認ください。
                  </Text>
                )}

                {selectedCrop && selectedGrowthStage && (
                  <>
                    <Text style={styles.label}>育成通知メッセージを入力:　
                    {message.trim() && (
                     <FontAwesome5 name="check" size={16} color="green" />
                    )}
                    </Text>
                    <TextInput
                      style={styles.messageInput}
                      placeholder="ピーマンが、〇〇cmに成長しました!など"
                      placeholderTextColor="gray"
                      value={message}
                      onChangeText={(text) => this.setState({ message: text })}
                      editable={true}
                      multiline={true}
                      numberOfLines={4}
                    />

                    {/* 画像選択ボタン */}
                    <Text style={styles.label}>画像を選択（任意）</Text>
                    <TouchableOpacity style={styles.imagePickerButton} onPress={() => this.pickImage()}>
                      <Text style={styles.imagePickerButtonText}>
                        画像を選択
                      </Text>
                    </TouchableOpacity>

                    {/* 画像プレビュー */}
                    {image && (
                      <View style={styles.imagePreviewContainer}>
                        <Image source={{ uri: image }} style={styles.imagePreview} />
                        <TouchableOpacity
                          style={styles.removeImageButton}
                          onPress={() => this.removeImage()}
                        >
                          <Text style={styles.removeImageButtonText}> × </Text>
                        </TouchableOpacity>
                      </View>
                    )}

                    {/* メッセージが入力されていない場合、送信ボタンは無効 */}
                    <TouchableOpacity
                      style={[styles.submitButton, { opacity: message.trim() ? 1 : 0.5 }]}
                      onPress={() => this.handleSubmit()}
                      disabled={!message.trim()}
                    >
                      <Text style={styles.submitButtonText}>育成メッセージ 送信</Text>
                    </TouchableOpacity>
                  </>
                )}
              </View>
            </View>
          </View>
        </Modal>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonIcon_ikusei: {
    color: '#63E6BE',
    marginTop: 0,
    textAlign: 'center',
  },
  modalBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    width: '80%',
    maxWidth: 400,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  modalContainerTablet: {
    width: '60%',
  },
  closeContainer: {
    alignItems: 'flex-end',
  },
  closeButtonContainer: {
    padding: 10,
  },
  closeButtonText: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  modalMain: {
    marginTop: 20,
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
    color: '#333',
  },
  picker: {
    width: '100%',
    height: 50,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    marginBottom: 20,
  },
  submitButton: {
    backgroundColor: '#3498db',
    padding: 15,
    borderRadius: 5,
    marginTop: 20,
  },
  submitButtonText: {
    color: 'white',
    textAlign: 'center',
    fontSize: 16,
  },
  apiResponseContainer: {
    marginBottom: 20,
  },
  growthStageRow: {
    marginBottom: 20,
  },
  currentStageText: {
    color: '#555',
    marginTop: 5,
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  messageInput: {
    height: 100,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    marginBottom: 20,
    padding: 10,
    textAlignVertical: 'top',
  },
  imagePickerButton: {
    backgroundColor: '#4CAF50',
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  imagePickerButtonText: {
    color: 'white',
    textAlign: 'center',
  },
  imagePreviewContainer: {
    marginBottom: 20,
    alignItems: 'center',
  },
  imagePreview: {
    width: 100,
    height: 100,
    borderRadius: 5,
    marginTop: 10,
  },
  removeImageButton: {
    marginTop: 10,
    backgroundColor: '#E74C3C',
    padding: 5,
    borderRadius: 5,
  },
  removeImageButtonText: {
    color: 'white',
  },
  loadingText: {
    fontSize: 16,
    color: '#888',
    textAlign: 'center',
  },
});
